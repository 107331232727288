import React from "react"
import { Link } from "gatsby"

const CallToAction = (props) => (
    <div className="max-w-screen-xl mx-auto lg:mb-16 lg:flex lg:justify-between lg:place-items-center">
        <h2 className="p-6 text-xl h1 md:text-center lg:text-left lg:py-0 lg:m-0 lg:text-2xl 2xl:p-0">{props.callToAction && props.callToAction.title ? props.callToAction.title : 'We are looking forward to help you with your questions.'}</h2>
        <div className="px-6 py-4 text-center bg-primary-bg lg:bg-white lg:flex lg:flex-shrink-0 lg:items-center lg:gap-4 2xl:p-0">
            <div className="-mx-2 text-sm lg:hidden">
                <span className="mx-2 font-bold cursor-default">Get answers</span>
                <a className="mx-2" href="tel:+49309203854012">+49 30 9203854012</a>
            </div>
            <a className="hidden btn btn--tertiary lg:block lg:mb-0" href="tel:+49309203854012"><strong className="mr-4">Get answers</strong><span>+49 30 9203854012</span></a>
            <a className="px-8 mt-4 font-bold btn btn--primary lg:hidden lg:m-0" href="tel:+49309203854012">Start a conversation</a>
            <Link className="hidden px-8 font-bold btn btn--primary lg:block lg:m-0" to="/contact">Get Rentware now</Link>
        </div>
    </div>
)

export default CallToAction