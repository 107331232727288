import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Nav from "../components/Nav"
import ContentPageHero from "../components/LandingPage/Hero"
import Feature from "../components/feature"
import CallToAction from "../components/CallToAction"
import Testimonial from "../components/Testimonial"

export const query = graphql`
  query($slug: String!) {
    rentwareFeaturePagesEnJson(slug: { eq: $slug }) {
      slug
      navLandingPage {
        navTelefonNumber
        navTelefonCta
        navTelefonNumberText
        navContactCtaMobile
        navContactCtaDesktop
      }
      hero {
        style
        headline
        subhead
        image {
          ...featureImage
        }
        imageStyle
      }
      features {
        textBlock {
          headline
          richText
        }
        featuresOverlappImage
        specialType
        url
        description
        image {
          ...featureImage
        }
        imageAlignment
        imageMaxWidth
      }
      testimonial {
        title
        text
        image {
          ...featureImage
        }
        rating {
          plattform
        }
        placeTestimonialAfterFeatureNumber
      }
    }
  }
`

// markup
const FeaturePageTemplate = ({ data }) => {
  var content = data.rentwareFeaturePagesEnJson
  const placeTestimonialAfterFeatureNumber = content.testimonial && content.testimonial.placeTestimonialAfterFeatureNumber ? content.testimonial.placeTestimonialAfterFeatureNumber : ""
  return (
    <Layout>
      <Nav
        telNumber={content.navLandingPage.navTelefonNumber}
        telCta={content.navLandingPage.navTelefonCta}
        telNumberText={content.navLandingPage.navTelefonNumberText}
        contactCtaMobile={content.navLandingPage.navContactCtaMobile}
        contactCtaDesktop={content.navLandingPage.navContactCtaDesktop}
      />
      <main>

      <ContentPageHero
          content={content.hero}
        />

        {content.features &&
          <div className="features">
            {content.features.map((feature, index) => {
              const orderSetByTestimonial = index >= (placeTestimonialAfterFeatureNumber) ? index + Number(placeTestimonialAfterFeatureNumber) : null
              return (
                <Feature
                  key={index}
                  feature={feature}
                  orderSetByTestimonial={orderSetByTestimonial}
                />
              )
            })}
            {content.testimonial &&
              <Testimonial
                testimonial={content.testimonial}
                placeTestimonialAfterFeatureNumber={Number(placeTestimonialAfterFeatureNumber) + 1}
              />
            }
          </div>
        }

        <CallToAction />
      </main>
    </Layout>
  )
}

export default FeaturePageTemplate
